import Link from '@material-ui/core/Link';
import {makeStyles} from '@material-ui/core/styles';
import {useRouter} from 'next/router';
import {MenuItem} from '@material-ui/core';
import React from 'react';

import {isAllowedToUser} from 'src/core/utils';
import {extendedTypography} from 'src/themes/themeNext/extendedTypography';

const useStyles = makeStyles(theme => ({
  menuItems: {marginBottom: theme.spacing(1)},
  menuItem: {
    minHeight: 42,
    padding: theme.spacing(1, 3),
    color: theme.palette.common.white,
    ...extendedTypography.body1MediumDesktop,

    '&:hover': {
      textDecoration: 'none',
      backgroundColor: theme.palette.colors.menuItem
    }
  },
  link: {
    color: theme.palette.common.white
  },
  menuItemSelected: {
    color: theme.palette.secondary.main
  },
  menuItemIcon: {
    minWidth: 20,
    marginRight: theme.spacing(1),
    color: 'inherit'
  }
}));

export const MobileLeftMenuItems = ({items, customer, onClose}) => {
  const classes = useStyles();
  const router = useRouter();

  return (
    <div className={classes.menuItems}>
      {items?.map(item => {
        if (!isAllowedToUser(item, customer)) {
          return null;
        }

        const href = item.page ? `/${item.page.slug}` : item.url;

        return (
          <MenuItem
            key={item.name}
            // component={Link}
            // href={href}
            className={classes.menuItem}
            selected={router.asPath === href}
            classes={{
              selected: classes.menuItemSelected
            }}
            onClick={() => {
              item.name === 'In-Game Services'
                ? window.open(href, '_blank')
                : router.push(href);
              onClose();
            }}
          >
            {item.name}
          </MenuItem>
        );
      })}
    </div>
  );
};
