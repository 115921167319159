import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import {useState} from 'react';
import {IconButton, Toolbar} from '@material-ui/core';

import useModal from 'src/hooks/useModal';
import {LOGIN_MODAL} from 'src/config';
import {CloseLargeIcon} from 'src/componentsNext/icons/actions/CloseLargeIcon';
import {MenuIcon} from 'src/componentsNext/icons/actions/MenuIcon';
import {maybe} from 'src/core/utils';
import {ProfileIcon} from 'src/componentsNext/icons/ProfileIcon';

import {LeftMenuDrawer} from './LeftMenuDrawer';
import {RightMenuDrawer} from './RightMenuDrawer';

import {LoginButton} from 'src/views/HeaderNext/components/LoginButton';
import {Logo} from 'src/views/Header/components/Logo';

const useStyles = makeStyles(theme => ({
  toolbar: {
    minHeight: 68,
    justifyContent: 'space-between'
  },
  menuOpened: {backgroundColor: theme.palette.primary.dark},
  hidden: {display: 'none'},
  icon: {
    color: theme.palette.secondary.main
  },
  leftIconButton: {
    padding: theme.spacing(0.5),
    marginLeft: -theme.spacing(1)
  },
  rightIconButton: {
    padding: theme.spacing(0.5)
    // marginLeft: -theme.spacing(1)
  },
  //designer insisted on placing logo in the middle no matter what
  logo: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)'
  }
}));

export const MobileMenu = ({menu, customer, isHidden = false}) => {
  const classes = useStyles();
  const {showModal} = useModal();
  const [isMenuDrawerOpen, setMenuDrawerOpen] = useState(false);
  const [isAccountDrawerOpen, setAccountDrawerOpen] = useState(false);

  const handleLogin = () => {
    showModal(LOGIN_MODAL);
  };

  const handleAccountDrawerToggle = () => {
    setMenuDrawerOpen(false);
    setAccountDrawerOpen(!isAccountDrawerOpen);
  };

  const handleMenuDrawerToggle = () => {
    setMenuDrawerOpen(!isMenuDrawerOpen);
    setAccountDrawerOpen(false);
  };

  return (
    <div
      className={clsx(
        classes.root,
        isHidden && classes.hidden,
        (isMenuDrawerOpen || isAccountDrawerOpen) && classes.menuOpened
      )}
    >
      <Toolbar className={classes.toolbar}>
        {isMenuDrawerOpen ? (
          <IconButton classes={{root: classes.leftIconButton}}>
            <CloseLargeIcon
              className={classes.icon}
              onClick={() => setMenuDrawerOpen(false)}
            />
          </IconButton>
        ) : (
          <IconButton
            classes={{root: classes.leftIconButton}}
            onClick={handleMenuDrawerToggle}
          >
            <MenuIcon className={classes.icon} />
          </IconButton>
        )}

        <div className={classes.logo}>
          <Logo width={88} />
        </div>

        {!isAccountDrawerOpen && customer && (
          <IconButton
            classes={{root: classes.rightIconButton}}
            onClick={handleAccountDrawerToggle}
          >
            <ProfileIcon className={classes.icon} />
          </IconButton>
        )}

        {isAccountDrawerOpen && customer && (
          <IconButton classes={{root: classes.leftIconButton}}>
            <CloseLargeIcon
              className={classes.icon}
              onClick={() => setAccountDrawerOpen(false)}
            />
          </IconButton>
        )}

        {!isAccountDrawerOpen && customer === null && (
          <LoginButton
            isMobile
            rounded
            size='small'
            color='secondary'
            variant='contained'
            className='mobile'
            onClick={handleLogin}
            style={{minWidth: 80}}
          >
            Login
          </LoginButton>
        )}

        <LeftMenuDrawer
          customer={customer}
          open={isMenuDrawerOpen}
          onClose={() => setMenuDrawerOpen(false)}
          items={maybe(() => menu.items, [])}
        />

        {customer && (
          <RightMenuDrawer
            customer={customer}
            open={isAccountDrawerOpen}
            onClose={() => setAccountDrawerOpen(false)}
          />
        )}
      </Toolbar>
    </div>
  );
};
