import Drawer from '@material-ui/core/Drawer';
import {makeStyles} from '@material-ui/core/styles';
import {ClickAwayListener} from '@material-ui/core';

import useStorefrontData from 'src/hooks/useStorefrontData';
import {CurrencyEnum} from 'src/core/enums';
import useModal from 'src/hooks/useModal';
import {LOGIN_MODAL} from 'src/config';

import {Divider} from 'src/views/HeaderNext/components/Divider';
import {SignUpButton} from 'src/views/HeaderNext/components/SignUpButton';
import {LoginButton} from 'src/views/HeaderNext/components/LoginButton';
import {MobileLeftMenuItems} from 'src/views/HeaderNext/components/MobileMenu/MobileLeftMenuItems';
import {CurrencySwitcher} from 'src/views/HeaderNext/components/CurrencySwitcher';

const useStyles = makeStyles(theme => ({
  modal: {marginTop: 68},
  drawerOpen: {
    width: '100%',
    marginTop: 68,
    paddingTop: theme.spacing(2),
    backgroundColor: theme.palette.colors.drawer,
    height: 'auto',
    borderTop: `0.5px solid ${theme.palette.secondary.main}`
  },
  backdrop: {
    top: 68
  },
  divider: {
    margin: theme.spacing(0, 3)
  },
  buttons: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(2),
    padding: theme.spacing(2, 3, 3, 3)
  }
}));

export const LeftMenuDrawer = ({open, onClose, customer, items}) => {
  const classes = useStyles();
  const {showModal} = useModal();

  const {currency, updateCurrency} = useStorefrontData();
  const currencies = [];
  Object.values(CurrencyEnum).forEach(value => {
    currencies.push(value);
  });

  const handleChangeCurrency = value => {
    updateCurrency(value);
  };

  return (
    <Drawer
      anchor='left'
      open={open}
      onClose={onClose}
      className={classes.root}
      classes={{
        modal: classes.modal,
        paper: classes.drawerOpen
      }}
      BackdropProps={{classes: {root: classes.backdrop}}}
    >
      <ClickAwayListener onClickAway={onClose}>
        <div>
          <MobileLeftMenuItems items={items} onClose={onClose} />

          <div className={classes.divider}>
            <Divider />
          </div>

          <CurrencySwitcher
            useInDrawer
            onChange={handleChangeCurrency}
            currency={currency}
            currencies={currencies}
            isCustomer={Boolean(customer)}
          />

          {!customer && (
            <>
              <div className={classes.divider}>
                <Divider />
              </div>
              <div className={classes.buttons}>
                <LoginButton
                  isMobile
                  rounded
                  size='small'
                  color='secondary'
                  onClick={() => showModal(LOGIN_MODAL)}
                />
                <SignUpButton fullWidth />
              </div>
            </>
          )}
        </div>
      </ClickAwayListener>
    </Drawer>
  );
};
